<template>
  <component :is="'b-card'">
    <edit-tab class="mt-1" />
  </component>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import timeStoreModule from '../obraStoreModule'
import EditTab from './EditTab.vue'

export default {
  components: {
    BCard,
    EditTab,
  },
  setup() {
    const OBRAS_APP_STORE_MODULE_NAME = 'app-obras'

    // Register module
    if (!store.hasModule(OBRAS_APP_STORE_MODULE_NAME)) store.registerModule(OBRAS_APP_STORE_MODULE_NAME, timeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OBRAS_APP_STORE_MODULE_NAME)) store.unregisterModule(OBRAS_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
